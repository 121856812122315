nav, .map {
  position: fixed;
  left: 2em;
  bottom: 4.75em;
  width: 8.5em;
  height: 8.5em;
  cursor: default;
  z-index: 500;
  font-size: 80%;
}

.map-silverring {
  position: fixed;
  left: 1.25em;
  bottom: 4em;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  border: .075em solid var(--contrast-color);
  font-size: 80%;
}

.map {
  border: .075em solid var(--contrast-color);

}

nav ul {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  position: absolute;
}

.nav-dot {
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: .5em;
  margin-top: .5em;
  border: 1px solid white;
  display: inline-block;
}

.nav-title {
  opacity: 0;
  transition: opacity .5s;
  position: absolute;
  position: fixed;
  left: 50%;
  top: 50%;
  font-size: 4em;
  /*text-shadow: 2px 2px 9px var(--pink), -2px -2px 9px var(--yellow);*/
  color: white;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  font-family: "Panama Iranic", monospace;
  pointer-events: none;
  mix-blend-mode: difference;
}

.nav-dot:hover,
.active .nav-dot {
  background: var(--contrast-color);
}

.nav-dot:hover + .nav-title {
  opacity: 1;
}

@media only screen and (min-width: 600px) {
  .nav-dot {
    width: .75em;
    height: .75em;
  }

  nav, .map {
    left: 1.75em;
    bottom: 3.75em;
    font-size: 90%;
  }

  .map-silverring {
    left: 1em;
    bottom: 3em;
    font-size: 90%;
  }
}

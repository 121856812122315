
.record {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -ms-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  color: var(--background-color);
  position: relative;
}

.record-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 750ms;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.record-cover, .record-tracklist {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.record,
.record-cover {
  width: calc(100vmin - 10em);
  height: calc(100vmin - 10em);
}

.record-tracklist {
  width: 100%;
  height: 100%;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: linear-gradient(var(--pink), rgba(255, 255, 255, 1));
  border: 1px solid white;
  text-align: left;
}

.record-cover img {
  width: 100%;
  height: auto;
  border-radius: .125em;
}

.record:hover .record-inner {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.record-headline {
  background: white;
  text-align: center;
  padding: .5em .5em .4em .5em;
  font-family: "Panama Iranic", monospace;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
}

.record-tracks {
  padding: .5em .5em .4em .5em;
}

.record-track {
  display: flex;
  justify-content: space-between;
}

.record-track-artists {
  display: none;
}

.record-track-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 83%;
}

.record-ext-links {
  padding: .5em .5em .4em .5em;
  text-align: center;
  position: absolute;
  bottom: .5em;
  width: 100%;
}

.record-ext-links a:link, .record-ext-links a:visited {
  border: 1px solid black;
  padding: .5em .65em .35em .65em;
  border-radius: .65em;
  color: black;
  text-transform: uppercase;
}

.record-ext-links a:hover {
  background: yellow;
}

.timeline-expand .record,
.timeline-expand .record-cover,
.timeline-expand .record-tracklist {
  width: 20em;
  height: 20em;
  max-width: calc(100vw - 4em);
  max-height: calc(100vw - 4em);
}

@media only screen and (min-width: 600px) {
  .record,
  .record-cover {
    width: 20em;
    height: 20em;
  }

  .record-track-artists {
    display: initial;
  }

}

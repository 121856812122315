.storage {
  font-family: "Panama", monospace;
  width: 100vw;
}

.storage .logo {
    width: 100%;
    height: 15em;
    margin-top: 1em;
}

.timeline-list {
  position: relative;
  list-style: none;
  padding: 0;
}

.timeline-entry {
  padding: .0em 0 .0em 0;
  background: rgba(200, 200, 200, .5);
  display: flex;
  justify-content: flex-start;
  transition: all .5s;
  position: relative;
  align-items: center;
  white-space: nowrap;
}

.timeline-entry-column {
  margin-inline-end: 1em;
}


.timeline-entry-title {
  /*text-align: center;*/
  text-transform: uppercase;
  letter-spacing: .05em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.timeline-entry-date {
  display: none;
}

.timeline-entry-transmission {
  background: none
}

.timeline-entry-record {
  background: none
}

.timeline-entry:hover {
  /*background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .25), rgba(255, 255, 255, 0));*/
  cursor: pointer;
  padding: .5em 0;
}

.timeline-expand {
  display: inline-block;
  padding: .5em 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timeline-expand .ticket,
.timeline-expand .record {
  /*position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);*/
}

@media only screen and (min-width: 600px) {
  .timeline-list {
    margin: 0 15%;
  }

  .timeline-filter {
    margin: 1em 15%;
    cursor: pointer;
  }

  .timeline-filter img {
    cursor: pointer;
  }

  .timeline-entry-date {
    display: initial;
  }
}

:root {
  --background-color: rgb(0, 0, 0);
  --contrast-color: rgb(255, 255, 255);
  --pink: rgb(238, 153, 255);
  --blue: rgb(102, 156, 255);
  --yellow: rgb(250, 229, 0);
  --purple: rgb(152, 162, 249);
  --intenseblue: rgb(5, 120, 200);
  --midnightblue: rgba(15, 15, 60);
  font-size: 80%;
}

@font-face {
  font-family: 'Panama';
  src: url('./assets/fonts/PanamaMonospaceRegular.woff') format('woff');
}

@font-face {
  font-family: 'Panama Iranic';
  src: url('./assets/fonts/PanamaMonospaceIranic.woff') format('woff');
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Panama', Arial;
  line-height: 1.25em;
  margin: 0;
  background: var(--background-color);
  color: var(--contrast-color);
}

.container {z-index: 300}
.intro {z-index: 999}
.corner {z-index: 900}
.bottom-gradient {z-index: 499}
.crew-card-quote {z-index: 200}
nav, .map, .map-silverring {z-index: 500}
.music-player {z-index: 500}
.marquee {z-index: 550}
.now-playing {z-index: 400}
.timeline-expand {z-index: 500}
.silverringen-object {z-index: 0;}

a:link, a:visited {
  color: yellow;
  text-decoration: none;
}

h1 {
  font: inherit;
  font-family: "Panama", monospace;
  font-size: 2em;
  text-transform: uppercase;
  color: var(--contrast-color);
  border-bottom: .075em solid var(--contrast-color);
  text-align: justify;
  text-align-last: justify;
  height: 1em;
  line-height: 1;
}

h1:after {
  content: "";
  display: inline-block;
  width: 100%;
}

h2 {
  font: inherit;
}

h2::before, h2::after {
  content: "///";
}

h2::before {
  margin-right: .3em;
}

h2::after {
  margin-left: .3em;
}

h3 {
  font: inherit;
  font-family: "Panama Iranic", monospace;
  font-size: 1.75em;
  text-align: center;
  margin: 0;
  margin-top: 1em;
}

.italic {
  font-style: italic;
}

.base-color {
  color: rgb(240, 240, 240);
}

.no-break {
  white-space: nowrap;
}

.cover-thumbnail {
  width: 14em;
  height: auto;
}

.hidden {
  display: none;
}

main {
  height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
}

.fixed {position: fixed;}
.absolute {position: absolute;}

@import "./stylesheets/Map.css";

.container {
  position: absolute;
  width: 100vw;
  padding: 5.25em 1em 1em 1em;
}

.iranic {
  font-family: "Panama Iranic", monospace;
}

.display-block {display: block}
.display-none {display: none}

.control-panel .logo {
    width: 100%;
    height: 10em;
}

.relative {
  position: relative;
}

button {
  background-color: inherit;
  border: 0;
  padding: 0;
  vertical-align: -.55em;
  margin-inline-end: .5em;
  transition: opacity 1s;
  filter: invert(1);
}

button:active {
  filter: invert(0);
}

button img {
  width: auto;
  height: 1.75em;
}

.icon {
  width: auto;
  height: 1.1em;
  filter: invert(1);
}

.uppercase {
  text-transform: uppercase;
}

.intro {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 1s ease;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro div {
  text-align: center;
  margin-top: 3em;
  width: calc(100vw - 4rem);
  max-width: 50em;
}

.intro img {
  filter: invert(1);
  width: 100%;
  height: 100%;
}

.intro p {
  margin: 2rem 0;
  font-size: 1.25em;
  line-height: 1.25em;
}

.opacity-0 {opacity: 0; pointer-events: none; transform: scale(10); filter: invert(1); animation: none;}

.embedded-image {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 600px) {
  :root {
    font-size: 90%;
  }

  .container {
    padding: 1rem;
  }

  .intro div {
    width: calc(100vw - 8rem);
  }

}

@media only screen and (min-width: 1600px) {
  :root {
    font-size: 100%;
  }
}

@media only screen and (min-width: 1800px) {
  :root {
    font-size: 110%;
  }
}

@media only screen and (min-width: 2000px) {
  :root {
    font-size: 120%;
  }
}


@import "./stylesheets/Decorations.css";
@import "./stylesheets/Record.css";
@import "./stylesheets/CrewCards.css";
@import "./stylesheets/Ticket.css";
@import "./stylesheets/MusicPlayer.css";
@import "./stylesheets/Keyboard.css";
@import "./stylesheets/DialogueBox.css";
@import "./stylesheets/Featured.css";
@import "./stylesheets/Timeline.css";

.ticket {
  width: 20em;
  font-family: "Panama", monospace;
  color: black;
  text-align: center;
  font-size: .95em;
  line-height: 1.1em;
  transition: all 2s;
  max-height: 200em;
  white-space: normal;
}

.ticket a:link,
.ticket a:visited {
  color: var(--midnightblue);
  text-transform: uppercase;

}

.ticket .logotype {
  width: 80%;
  margin-top: 1em;
}

.smaller {
  font-size: .8em;
}

.ticket-title {
  color: black;
  font: inherit;
  text-transform: uppercase;
  letter-spacing: .15em;
  text-align: center;
  margin: -.15em 0 -.3em 0;
  height: inherit;
  border: 0;
  text-align-last: inherit;
}

.ticket-title span:nth-child(1n),
.timeline-entry-title .nth:nth-child(2n) {
  vertical-align: .15em;
}

.ticket-title span:nth-child(2n),
.timeline-entry-title .nth:nth-child(2n) {
  vertical-align: -.15em;
}

.ticket-date {
}

.ticket-date p:first-child {
  margin-right: .5em;
}

.ticket ul {
  list-style: none;
  padding-left: 1.5em;
  margin-top: 0;
}

.ticket li {
  margin: 0;
}

.ticket li p {
  margin: 0;
}

.ticket-border {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  padding-top: 1em;
}

.ticket hr {
  border: 0;
  border-top: 1px dashed black;
}

.ticket .lineup {
  margin-bottom: 0;
}

.ticket-upper-cut,
.ticket-bottom-cut {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.ticket-bottom-cut {
  transform: rotateX(180deg);
}

.ticket-padding {
  padding: 1em;
  background: lightyellow;
}

.ticket-upper-cut div,
.ticket-bottom-cut div {
  width: 0;
  height: 0;
  border-style: none solid solid solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: lightyellow;
}

.ticket-right-shape {
  background: white;
  height: 10em;
  width: .5em;
  position: absolute;
  right: 100%;
  top: .5em;
}

.components {
  transform: translateZ(0);
}

.skiez {
  position: absolute;
  width: 2000em;
  height: 2000em;
}

.star {
  position: absolute;
  display: inline-block;
}

.star img {
  width: 3000px;
  height: 2000px;
}

.corner {
  pointer-events: none;
  width: 2.25em;
  height: 2.25em;
}

.corner:nth-child(1) {
  top: .5em;
  left: .5em;
}

.corner:nth-child(2) {
  top: .5em;
  right: .5em;
}

.corner:nth-child(3) {
  bottom: 2.75em;
  right: .5em;
}

.corner:nth-child(4) {
  bottom: 2.75em;
  left: .5em;
}

.corner img {
  width: 1.5em;
  height: auto;
  filter: invert(1);
}


.float-animation {
  animation: float 6s ease-in-out infinite;
}

.float-animation:hover {
  animation-play-state: paused;
}


@keyframes scale {
  0%    {transform: translate(2px, 2px)}
  25%   {transform: translate(2px, -2px)}
  50%   {transform: translate(-2px, -2px)}
  75%   {transform: translate(-2px, -2px)}
  100%  {transform: translate(2px, 2px)}
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateY(0) rotateX(0);
  }
  50% {
    transform: translate(-50%, -50%) rotateY(1turn) rotateX(1turn);
  }
  100% {
    transform: translate(-50%, -50%) rotateY(0) rotateX(0);
  }
}

@keyframes rotate2 {
  0% {transform: rotateZ(0)}
  100% {transform: rotateZ(1turn)}
}

@keyframes text-shadow {
  0% {text-shadow: -.25em -.25em .5em var(--blue), .25em .25em .5em var(--blue);}
  25% {text-shadow: -.25em -.25em .5em var(--pink), .25em .25em .5em var(--pink);}
  75% {text-shadow: -.25em -.25em .5em var(--purple), .25em .25em .5em var(--purple);}
  100% {text-shadow: -.25em -.25em .5em var(--blue), .25em .25em .5em var(--blue);}
}

.blue-text-shadow {
  text-shadow: -.25em -.25em .5em var(--blue), .25em .25em .5em var(--blue);
}

.purple-text-shadow {
  text-shadow: -.25em -.25em .5em var(--purple), .25em .25em .5em var(--purple);
}

.yellow-text-shadow {
  text-shadow: -.25em -.25em .5em var(--yellow), .25em .25em .5em var(--yellow);
}

.pink-text-shadow {
  text-shadow: -.25em -.25em .5em var(--pink), .25em .25em .5em var(--pink);
}

.animated-text-shadow {
  animation: text-shadow 30s infinite;
}

.shadow {
  -webkit-box-shadow:
    0 0 1em rgba(100, 100, 100, .15),
    inset 0 0 1em rgba(100, 100, 100, .15);
  -moz-box-shadow:
    0 0 1em rgba(100, 100, 100, .15),
    inset 0 0 1em rgba(100, 100, 100, .15);
  -o-box-shadow:
    0 0 1em rgba(100, 100, 100, .15),
    inset 0 0 1em rgba(100, 100, 100, .15);
  box-shadow:
    0 0 1em rgba(100, 100, 100, .15),
    inset 0 0 1em rgba(100, 100, 100, .15);
}

.shadow-filter {
  -webkit-filter: drop-shadow(0 0 1em rgba(0,0,0,0.75));
  filter: drop-shadow(0 0 1em rgba(0,0,0,0.75));
}

.blue-shadow {
  -webkit-box-shadow: 0 0 2em var(--blue);
  -moz-box-shadow: 0 0 2em var(--blue);
  -o-box-shadow: 0 0 2em var(--blue);
  box-shadow: 0 0 2em var(--blue);
}

.yellow-shadow {
  -webkit-box-shadow: 0 0 2em rgba(250, 229, 0, .25);
  -moz-box-shadow: 0 0 2em rgba(250, 229, 0, .25);
  -o-box-shadow: 0 0 2em rgba(250, 229, 0, .25);
  box-shadow: 0 0 2em rgba(250, 229, 0, .25);
}

.pink-shadow {
  -webkit-box-shadow: 0 0 2em var(--pink);
  -moz-box-shadow: 0 0 2em var(--pink);
  -o-box-shadow: 0 0 2em var(--pink);
  box-shadow: 0 0 2em var(--pink);
}

.bottom-gradient {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 20em;
  background: linear-gradient(rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1));
  pointer-events: none;
}

@media only screen and (min-width: 600px) {

  .silverring-holder{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;

  }

  .silverringen-object {
    width: calc(100vmin - 7em);
    height: calc(100vmin - 7em);
    border: 1px solid white;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: rotate 70s linear infinite;
    pointer-events: none;
    background: none;
  }

  .corner:nth-child(3), .corner:nth-child(4) {
    bottom: 2.25em;
  }
}

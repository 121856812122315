.music-player {
  position: fixed;
  right: 1.25em;
  bottom: 4em;
  font-size: 80%;
}

.decks {
  display: flex;
}

.record-outer-circle {
  width: 10em;
  height: 10em;
  border: 1px solid var(--contrast-color);
  border-radius: 50%;
  animation: rotate2 5s infinite linear;
  margin-left: .5em;
  transition: 1s opacity;
  cursor: pointer;
}

.record-paused {
  animation-play-state: paused;
}

.no-track {
  opacity: .25;
}

.record-label-circle {
  width: 4em;
  height: 4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid var(--contrast-color);
  cursor: initial;
  display: none;
}

.record-inner-circle {
  width: .75em;
  height: .75em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid var(--contrast-color);
  background: var(--background-color);
  cursor: initial;
}

.record-tag {
  position: relative;
  width: 3em;
  height: 1.5em;
  top: 1.55em;
  left: 3.5em;
  background: var(--contrast-color);
  border: 1px solid var(--contrast-color);
  text-align: center;
  font-family: "Panama", monospace;
  color: var(--background-color);
}

.record-tag img {
  width: 100%;
  height: auto;
}



.o-1 {opacity: 1}
.o-0 {opacity: 0}




.marquee, .now-playing {
  position: fixed;
  font-family: "Panama", Arial;
  text-transform: uppercase;
}

.marquee {
  background: black;
  display: flex;
  width: 100vw;
  left: 0;
  bottom: 0;
  font-size: 1em;
}

.marquee img {
  position: relative;
  top: .2em;
  height: 1em;
  width: 12em;
  filter: invert(0);
  margin: 0 4em;
}

.marquee span {
  display: inline-block;
  margin: .5em 0 .4em 0;
}

.now-playing {
  color: var(--contrast-color);
  text-align: center;
  opacity: 1;
  left: 50%;
  top: 2em;
  transform: translateX(-50%);
  white-space: nowrap;
  border: .075em solid var(--contrast-color);
  padding: .6em .6em .4em .6em;
  transition: all 2s;
  border-radius: .5em;
  font-size: 130%;
  cursor: pointer;
}


@media only screen and (min-width: 600px) {
  .music-player {
    right: 1em;
    bottom: 3em;
    font-size: 90%;
  }

  .now-playing {
    bottom: 5.5em;
    top: auto;
    font-size: 1.12em;
  }

  .marquee span {
    margin: .25em 0 .1em 0;
  }

  .marquee img {
    top: .2em;
    height: 1em;
  }
}

.featured-misc {
  position: relative;
  text-align: center;
}

.featured-seller {
  font-size: 1.25em;
  line-height: 1.2em;
  padding: 0em 1em;
}

.featured-logo {
    width: calc(100vmin - 8rem);
    position: relative;
    height: auto;
    margin: 1em 0 .25em 0;
    filter: invert(1);
}

.featured-entry {
  position: relative;
  display: inline-block;
  margin: 1em 4em;
}

.featured-flex {
  margin-top: 2em;
  cursor: grab;
}

@media only screen and (min-width: 600px) {
  .featured-misc {
    margin-top: 42.5vh;
    transform: translateY(-50%);
  }

  .featured-flex {
    display: flex;
    justify-content: center;
    margin-top: -90vh;
  }

  .featured-logo {
    width: calc(100vmin - 4rem);
  }

  .featured-seller {
    font-size: 1.25em;
    line-height: 1.2em;
    max-width: 45em;
    margin: 2rem auto;
  }

  .featured-entry {
    position: relative;
    margin: 0;
  }
}

.keyboard {
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.key {
  text-align: center;
  width: 6em;
  padding: 1.25em 0 1em 0;
  border: .1em solid  var(--pink);
  border-bottom: .2em solid var(--pink);
  border-right: .2em solid var(--pink);
  margin: .25em;
  border-radius: .5em;
  min-height: 3em;
  transition: all .5s;
  position: relative;
  box-shadow: inset 2px 2px 8px rgba(255, 255, 255, .75), 0 0 2em rgba(250, 229, 0, .25);
}

.key-dynamic {
  display: none;
}

.key:hover {
  transform: scale(.9);
}

.key-assigned {
  font-size: .8em;
  position: absolute;
  right: .6em;
  top: .25em;
  text-transform: uppercase;
}

.visibility-hidden {
  visibility: hidden;
  width: 2.5em;
}

.control-panel-release {
  display: inline-block;
  margin: 4em auto 3em auto;
  text-align: center;
}

.control-panel-release .image-neue {
  margin-top: 1em;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 360px) {
  .key {
    font-size: .9em;
  }
}

@media only screen and (min-width: 600px) {

  .keyboard {
    width: 57em;
    margin-bottom: 8em;
  }

  .key {
    width: 7.5em;
    margin: .25em;
    min-height: 4em;
  }

  .key-dynamic {
    display: block;
  }

  .visibility-hidden {
    width: 4em;
  }
}



.dialogue-box {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1.25em;
  border: 1px solid white;
  background: linear-gradient(rgba(100, 100, 100, 0) 20%, rgba(100, 100, 100, 0));
}

.dialogue-box h5 a:link,
.dialogue-box h5 a:visited {
  color: white;
}

.dialogue-box h5 {
  font: inherit;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1em;
  display: inline-block;
}

.dialogue-box-title {
  background: white;
  font-family: "Panama Iranic", monospace;
  text-align: center;
  padding: .5em .5em .2em .5em;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  color: var(--background-color);
}



.dialogue-box-about hr {
  border: 0;
  border-top: 1px solid white;
}

.dialogue-box-title hr {
  position: absolute;
  border: 0;
  border-top: 1px solid black;
  left: 0;
  width: 100%;
  margin: 0;
}


.dialogue-box-title span {
  position: relative;
  z-index: 10;
  background: white;
  vertical-align: .1em;
}

.dialogue-box-main {
}

.dialogue-box-navigation {
  left: -11em;
  padding: 1em 1em 0 1em;
}

.dialogue-box-navigation-headline {
  border-bottom: 1px solid white;
  font-family: "Panama", monospace;
  text-align: center;
  padding: .2em .5em .3em .5em;
}

.dialogue-box-navigation ul {
  list-style-type: none;
  display: flex;
  margin: 0 -.5em;
  padding: 0;
}

.dialogue-box-navigation li {
  padding: .75em 1em;
  text-align: center;
  border: 1px solid white;
  border-radius: .5em;
  margin: .5em;
  transition: background 500ms ease;
  box-shadow: 0 0 1em var(--pink), inset 0 0 .5em rgb(255, 255, 255, .25);
  white-space: nowrap;
  font-size: .85em;
  cursor: pointer;
}

.dialogue-box-navigation li:hover {
  text-transform: uppercase;
}

.dialogue-box-navigation li.active {
  background: rgb(230, 230, 230);
  box-shadow: 0 0 1em var(--pink), inset 0 0 .5em rgb(255, 255, 255, 1);
  text-transform: uppercase;
  color: black;
}

.dialogue-box-content {
  padding: 1.25em;
  padding-top: 0;
}

.dialogue-box-post {
  transition: opacity 500ms ease;
}

.dialogue-box-about {
  text-align: center;
  border-bottom: 1px solid white;
}

.dialogue-box .embedded-image {
  width: 20em;
  box-shadow: 0 0 .5em var(--yellow);
}

.dialogue-box p {
  margin-left: 2em;
  margin-right: 2em;
}

.opacity-half {
  opacity: .5;
}

.opacity-quarter {
  opacity: .25;
}

@media only screen and (min-width: 600px) {
  .dialogue-box {
    width: 50em;
  }

  .dialogue-box-navigation li {
    font-size: 1rem;
  }
}

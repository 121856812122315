.crew {
  width: 100vw;
  padding: 1em;
  text-align: center;
}

.crew .logotype {
  margin-top: 1em;
  filter: invert(1);
}

.crew-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  margin-top: 1em;
}

.crew-card-link {
  position: relative;
}

.crew-card {
  position: relative;
  background: white;
  border-radius: .5em;
  width: 20em;
  padding: .5em;
  margin: 1em;
  color: black;
}

.crew-card-information {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  margin: .25em 0 .5em 0;
  font-size: .75em;
  line-height: 1.1em;
}

.crew-card-information-l2 {
  flex: 0 50%;
  text-transform: uppercase;
  letter-spacing: .025em;
  padding: .5em;
  text-align: left;
  display: flex;
  align-items: center;
  font-family: Arial;
}

.crew-card-information-l3 img {
  height: 1.5em;
  width: auto;
  vertical-align: -.375em;
  margin-right: .5em;
}

.crew-card-information-l2:nth-child(1) {
  border-right: 1px dashed black;
  border-bottom: 1px dashed black;
}

.crew-card-information-l2:nth-child(2) {
  border-bottom: 1px dashed black;
}

.crew-card-information-l2:nth-child(3) {
  border-right: 1px dashed black;
}

.crew-card-title {
  font-family: "Panama", monospace;
  font-size: 1.2em;
  padding: .25em .25em 0 .25em;
  text-transform: uppercase;
}

.crew-card-avatar {
  width: 100%;
  position: relative;
}

.crew-card-logotype-s {
  position: absolute;
  width: 22.5%;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.crew-card-logotype-s img {
  margin: 0 auto;
}

.crew-card-quote {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Panama", monospace;
  font-size: 1.75em;
  line-height: 1.2em;
  white-space: nowrap;
  color: var(--contrast-color);
  text-shadow: -.25em -.25em .5em var(--blue), .25em .25em .5em var(--blue), 0 0 1em var(--yellow);
  text-shadow: -.25em -.25em .5em var(--blue), .25em .25em .5em var(--blue),
  -1px 1px 0 #000,
        1px 1px 0 #000,
       1px -1px 0 #000,
      -1px -1px 0 #000;
  border-radius: 1em;
  -webkit-text-stroke: 1px var(--yellow);

}

.crew-card:hover + .crew-card-quote {
  opacity: 1;
}

.aim {
  width: 100%;
  min-height: 16em;
  position: relative;
  pointer-events: none;
}

.aim img {
  width: 1.25em;
  height: auto;
}

.aim-corner:nth-child(2) {
  top: 0;
  right: 0;
}

.aim-corner:nth-child(3) {
  bottom: 0;
  right: 0;
}

.aim-corner:nth-child(4) {
  bottom: 0;
  left: 0;
}

.crew-card-logotype {
  width: 100%;
}

.crew-card-logotype img {
}

.crew-card-avatar-video {
  width: 100%;
  position: absolute;
  height: 15em;
}

.crew-card-avatar-video * {
  position: relative;
  top: 52%;
  transform: translateY(-50%);
}

.crew-card-avatar-video video {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 600px) {
.crew-card {
  animation: none;
}
}

@media only screen and (min-width: 600px) {


  .crew-card {
    margin: 2em 3em;
  }

  .crew .logotype {
    margin-top: 1em;
    margin-right: -1em;
    filter: invert(1);
  }

}

@media only screen and (min-width: 1150px) {

  .crew-list {
    width: 56em;
    margin: 0 auto;
    margin-top: 1em;
  }

}

@media only screen and (min-width: 1700px) {

  .crew-list {
    width: auto;
  }

}
